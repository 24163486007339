import React from 'react';
import { Checkbox, Col, Image, Row, Select, Typography } from 'antd';
import { fallbackUrl } from 'helpers/utils';
import { useFormContext } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { useProviderContext } from '../ProviderContext';

const { Title, Paragraph } = Typography;

const Step1: React.FC = () => {
  const { control, watch } = useFormContext();
  const stepOne = watch('stepOne');
  const { providerMongoDBData: providerData } = useProviderContext();
  return (
    <>
      <Row>
        <Col span={4}>
          <Image
            width={300}
            src={providerData?.governmentId?.frontImage}
            fallback={fallbackUrl}
          />
        </Col>
        <Col span={4} offset={4}>
          <Image
            width={300}
            src={providerData?.uploadedImage}
            fallback={fallbackUrl}
          />
        </Col>
        <Col span={4} offset={4}>
          <Image
            width={300}
            src={providerData?.verifiedImage}
            fallback={fallbackUrl}
          />
        </Col>
      </Row>

      <Typography>
        <Title level={4}>Tips</Title>

        <Paragraph>
          <li>
            Examine Features: Compare facial features and distinguishing marks
            closely in both images.
          </li>
        </Paragraph>

        <Paragraph>
          <li>
            Check Validity: Ensure the ID is valid and the headshot matches the
            ID&lsquo;s photo and details.
          </li>
        </Paragraph>
        <Paragraph>
          <li>
            Avoid Bias: Maintain objectivity, avoiding assumptions based on the
            individual&lsquo;s appearance.
          </li>
        </Paragraph>
      </Typography>

      <FormItem
        name="stepOne"
        label={
          <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
            Does the picture belong to the same person ?
          </p>
        }
        control={control}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Select
          style={{ width: 80 }}
          options={[
            { value: true, label: 'yes' },
            { value: false, label: 'no' },
          ]}
        />
      </FormItem>

      {stepOne === false ? (
        <FormItem name="overrideId" valuePropName="checked" control={control}>
          <Checkbox>Override ID Verification?</Checkbox>
        </FormItem>
      ) : (
        <></>
      )}
    </>
  );
};

export default Step1;

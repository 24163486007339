/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, message } from 'antd';
import { HttpStatusCode } from 'axios';
import Loading from 'components/Loading/Loading';
import { checkIfProviderFromUS } from 'helpers/utils';
import {
  ProviderAirtableData,
  ProviderMongoDBData,
  VerificationData,
} from 'interfaces';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fetchProviderDataFromAirTable,
  getVerificationForm,
  setAirtableProviderVerificationStatus,
  submitVerificationForm,
} from 'services/api';
import { useAuth } from 'services/auth';
import { ProviderContextProvider } from './ProviderContext';
import VerificationFormSteps, {
  STEPS_COUNT,
} from './Steps/VerificationFormSteps';
import Error from '../Error';

const VerificationForm: React.FC = () => {
  const navigate = useNavigate();
  const [hasError, setHasError] = useState<boolean>(false);
  const [providerExistsOnAirtable, setProviderExistsOnAirtable] =
    useState<boolean>(true);
  const { providerId } = useParams();
  const { loggedInUser } = useAuth();
  const [providerMongoDBData, setProviderMongoDBData] =
    useState<ProviderMongoDBData>();
  const [providerAirtableData, setProviderAirtableData] =
    useState<ProviderAirtableData>();
  const [providerVerificationForm, setProviderVerificationForm] =
    useState<VerificationData>();
  const [currentStep, setCurrentStep] = useState<number>(
    providerVerificationForm?.currentStep || 0
  );
  const methods = useForm<VerificationData>({
    defaultValues: providerVerificationForm,
  });
  const { handleSubmit, setValue, getValues, reset, watch } = methods;
  useEffect(() => {
    const fetchVerificationData = async () => {
      try {
        const { data } = await getVerificationForm(providerId as string);
        const airtableData = await fetchProviderDataFromAirTable(
          data.providerData.email
        );
        if (airtableData.status === HttpStatusCode.NotFound) {
          setProviderExistsOnAirtable(false);
          return;
        }
        setProviderMongoDBData(data.providerData);
        setProviderVerificationForm(data.verificationData);
        setProviderAirtableData(airtableData.data);
        setCurrentStep(data.verificationData?.currentStep);
      } catch (error) {
        console.error('Error fetching new sign-ups:', error);
        setHasError(true);
      }
    };
    fetchVerificationData();
  }, []);
  useEffect(() => {
    reset(providerVerificationForm);
  }, [providerVerificationForm]);

  const [isValidIdVerification, isValidLegalVerification, redFlags] = watch([
    'stepOne',
    'stepTwo',
    'redFlags',
  ]);

  const overrideId = watch('overrideId', false);

  const isProviderFromUS = checkIfProviderFromUS(providerMongoDBData);

  const setProviderVerificationStatus = async (
    providerVerificationStatus: 'approved' | 'rejected'
  ) => {
    try {
      if (
        providerVerificationStatus !== 'approved' &&
        providerVerificationStatus !== 'rejected'
      )
        return;
      const providerEmail =
        providerMongoDBData?.email ||
        providerAirtableData?.providerPersonalEmailId ||
        providerAirtableData?.deelEmailId ||
        providerAirtableData?.wellniteEmailId;

      if (!providerEmail) {
        message.error('Error fetching provider email');
        return;
      }
      if (isProviderFromUS) {
        await setAirtableProviderVerificationStatus(
          providerEmail,
          providerVerificationStatus
        );
      }
      message.success('Provider verification status updated');
    } catch (error) {
      console.error('Error setting provider verification status:', error);
      message.error('Error setting provider verification status');
      setHasError(true);
    }
  };

  const STEPS_COUNT_TYPED = isProviderFromUS
    ? STEPS_COUNT - 1
    : STEPS_COUNT - 3;
  const isLastStep = currentStep === STEPS_COUNT_TYPED;
  const getTotalRedflags = () => {
    const { stepOne, stepThree, stepFour, stepFive, stepSix } = getValues();
    const totalRedflags =
      (!stepOne ? 1 : 0) +
      (stepThree ?? 0) +
      (stepFour ?? 0) +
      (stepFive ?? 0) +
      (stepSix ?? 0);
    setValue('redFlags', totalRedflags);
    return totalRedflags;
  };

  const onSubmit = (data: VerificationData) => {
    if (!isLastStep) {
      setCurrentStep((prevStep) => {
        data.currentStep = prevStep + 1;
        data.status = 'ongoing';
        return prevStep + 1;
      });
    }
    if (isLastStep) {
      data.status = 'approved';
      navigate(-1);
      setProviderVerificationStatus(data.status);
    }
    data.redFlags = getTotalRedflags();
    submitVerificationForm(providerId as string, loggedInUser.email, data);
  };

  const prevStep = () => {
    const data: VerificationData = {
      ...getValues(),
    };
    setCurrentStep((prevStep) => {
      data.currentStep = prevStep - 1;
      return prevStep - 1;
    });
    data.redFlags = getTotalRedflags();
    submitVerificationForm(providerId as string, loggedInUser.email, data);
  };

  const rejectProvider = () => {
    const data: VerificationData = {
      ...getValues(),
    };
    data.status = 'rejected';
    submitVerificationForm(providerId as string, loggedInUser.email, data);
    navigate(-1);
  };

  if (hasError) {
    return <Error />;
  }

  if (!providerExistsOnAirtable) {
    return <Error errorText="Provider doesn't exist on Airtable" />;
  }

  const boxStyle: React.CSSProperties = {
    width: '100%',
  };

  const showRejected = () => {
    if (![0, 1].includes(currentStep) && !isLastStep) return false;
    if (
      currentStep === 0 &&
      isValidIdVerification === false &&
      overrideId === false
    )
      return true;
    if (currentStep === 1 && isValidLegalVerification === false) return true;
    if (isLastStep && redFlags > 0) return true;
  };

  if (
    !providerVerificationForm ||
    !providerMongoDBData ||
    !providerAirtableData
  ) {
    return <Loading />;
  }
  return (
    <>
      <ProviderContextProvider
        value={{
          providerMongoDBData: providerMongoDBData,
          providerAirtableData: providerAirtableData,
        }}
      >
        <FormProvider {...methods}>
          <Form onFinish={handleSubmit(onSubmit)}>
            <VerificationFormSteps currentStep={currentStep} />
            <Flex style={boxStyle} justify={'space-evenly'} align={'flex-end'}>
              <Button disabled={currentStep === 0} onClick={prevStep}>
                {' '}
                Previous
              </Button>
              {/* <Button
                style={{ backgroundColor: '#FCD966', color: '#000000' }}
                onClick={() => message.success('Notification!')}
              >
                Notify
              </Button> */}
              {showRejected() ? (
                <Button type="primary" danger onClick={rejectProvider}>
                  Reject
                </Button>
              ) : (
                <Button
                  onKeyDown={(e) =>
                    e.key == 'Enter' ? e.preventDefault() : ''
                  }
                  type="primary"
                  htmlType="submit"
                  disabled={isLastStep && redFlags > 0}
                >
                  {isLastStep ? 'Approve' : 'Next'}
                </Button>
              )}
            </Flex>
          </Form>
        </FormProvider>
      </ProviderContextProvider>
    </>
  );
};

export default VerificationForm;

import React from 'react';
import type { TableProps } from 'antd';
import { Button, Flex, Table, Tag } from 'antd';
import { LegalActionsSearchResult } from 'interfaces';
import { useLegalActionsCheckerContext } from './LegalActionsCheckerContext';

const columns: TableProps<LegalActionsSearchResult>['columns'] = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (text, record) => (
      <a href={record.link} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    ),
  },
  {
    title: 'Snippet',
    dataIndex: 'snippet',
    key: 'snippet',
  },
  {
    title: 'Tag',
    dataIndex: 'tag',
    key: 'tag',
    width: 150,
    render: (text) => (
      <Tag color={text === 'Not Related' ? 'geekblue' : 'red'}>{text}</Tag>
    ),
  },
];

const ResultTable: React.FC = () => {
  const { legalActionsResults, page, setPage, isLoading } =
    useLegalActionsCheckerContext();
  return (
    <>
      <Table
        columns={columns}
        dataSource={legalActionsResults}
        pagination={{ defaultPageSize: 10, position: ['none'] }}
        rowKey="position"
        loading={isLoading}
      />
      <Flex
        gap="large"
        justify="center"
        style={{ marginTop: 10, marginBottom: 20 }}
      >
        <Button
          onClick={() => setPage(page - 1)}
          disabled={page === 1 || isLoading}
        >
          Previous
        </Button>
        <Button
          onClick={() => setPage(page + 1)}
          type="primary"
          disabled={isLoading}
          loading={isLoading}
        >
          Next
        </Button>
      </Flex>
    </>
  );
};

export default ResultTable;

import { countryFieldFilters } from 'constants/filters';
import { statusFieldFilters } from 'constants/filters';
import React, { FC, useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {
  Table,
  Tag,
  Button,
  Input,
  Space,
  ConfigProvider,
  Radio,
  Badge,
  message,
} from 'antd';
import type { GetProp, GetRef, TableColumnType } from 'antd';
import type { TableProps } from 'antd';
import type {
  FilterDropdownProps,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import './NewSignUps.css';
import { HttpStatusCode } from 'axios';
import { ProviderSignUp } from 'interfaces';
import { useNavigate } from 'react-router-dom';
import { fetchNewSignUps, fetchUnverifiedProvidersCount } from 'services/api';

type ColumnsType<T extends object> = TableProps<T>['columns'];
type InputRef = GetRef<typeof Input>;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: SorterResult<never>['field'];
  sortOrder?: SorterResult<never>['order'];
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

// type DataIndex = keyof ProviderSignUp;
const NewSignUps: FC = () => {
  const [signUps, setSignUps] = useState<ProviderSignUp[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unverifiedTherapistsCount, setUnverifiedTherapistsCount] =
    useState<number>(0);
  const [unverifiedPrescribersCount, setUnverifiedPrescribersCount] =
    useState<number>(0);
  const searchInput = useRef<InputRef>(null);
  const [selectedRole, setSelectedRole] = useState<string>('therapist');
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'createdAt',
    sortOrder: 'descend',
  });
  const navigate = useNavigate();
  function handleRowClick(record: ProviderSignUp) {
    // Stop onClick if selecting text
    if (window.getSelection()?.toString()) return;
    if (record.status === 'approved' || record.status === 'rejected') {
      return;
    }
    navigate(`/signups/${record.providerId}`);
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm']
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const queryParams = new URLSearchParams();
        queryParams.append('role', selectedRole);
        tableParams.pagination &&
          queryParams.append('page', String(tableParams.pagination?.current));
        tableParams.pagination &&
          queryParams.append('limit', String(tableParams.pagination?.pageSize));
        tableParams.sortField &&
          queryParams.append('sortBy', String(tableParams.sortField));
        tableParams.sortOrder &&
          queryParams.append('sortOrder', String(tableParams.sortOrder));
        tableParams.filters &&
          queryParams.append('filters', JSON.stringify(tableParams.filters));
        const query = queryParams.toString();
        const response = await fetchNewSignUps(query);
        if (response.status === HttpStatusCode.Ok) {
          setSignUps(response.data.providers);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: response.data.totalProviders,
            },
          });
        } else {
          throw new Error('Error fetching new sign-ups');
        }
      } catch (error) {
        message.error('Error fetching new sign-ups');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [
    selectedRole,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
  ]);

  useEffect(() => {
    const fetchUnverifiedProviders = async () => {
      try {
        const response = await fetchUnverifiedProvidersCount();
        if (response.status === HttpStatusCode.Ok) {
          setUnverifiedTherapistsCount(response.data.unverifiedTherapistsCount);
          setUnverifiedPrescribersCount(
            response.data.unverifiedPrescribersCount
          );
        } else {
          throw new Error('Error fetching unverified providers count');
        }
      } catch (error) {
        message.error('Error fetching unverified providers count');
      }
    };
    fetchUnverifiedProviders();
  }, [selectedRole]);

  const getColumnSearchProps = (
    title: string
    // dataIndex: DataIndex
  ): TableColumnType<ProviderSignUp> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys as string[], confirm);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    // onFilter: (value, record) => {
    //   if (record[dataIndex]) {
    //     return (
    //       record[dataIndex]
    //         ?.toString()
    //         .toLowerCase()
    //         .includes((value as string).toLowerCase()) || false
    //     );
    //   }
    //   return false;
    // },
  });

  const getAlternateDate = (date: Date, record: ProviderSignUp) => {
    let dateChoice = date;
    if (!date) {
      dateChoice = record.createdAtTimestamp;
    }
    return `${new Date(dateChoice).toLocaleString(undefined, { timeZoneName: 'short' })}`;
  };
  const columns: ColumnsType<ProviderSignUp> = [
    {
      title: 'Provider Name',
      dataIndex: 'fullName',
      key: 'fullName',
      ...getColumnSearchProps('Provider Name'),
    },
    {
      title: 'Signup Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date, record) => getAlternateDate(date, record),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getColumnSearchProps('Phone Number'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('Email'),
    },
    {
      title: 'Regions',
      dataIndex: 'states',
      key: 'states',
      render: (state: string[]) => <>{state?.join(', ')}</>,
    },
    {
      title: 'Country',
      dataIndex: 'countryName',
      key: 'countryName',
      filters: countryFieldFilters,
      onFilter: (value, record) => record.countryName === value,
    },
    {
      title: 'Red Flags',
      key: 'redFlags',
      dataIndex: 'redFlags',
      render: (score: number) => (
        <Tag
          color={score === 0 ? 'green' : `rgb(${255 - score * 10}, 0, 0)`}
          key={score}
        >
          {score}
        </Tag>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: string) => (
        <Tag
          color={
            status === 'approved'
              ? 'green'
              : status === 'rejected'
                ? 'red'
                : status === 'not started'
                  ? 'orange'
                  : 'geekblue'
          }
          key={status}
        >
          {status}
        </Tag>
      ),
      filters: statusFieldFilters,
      onFilter: (value, record) => record.status === value,
    },
  ];

  const handleTableChange: TableProps<ProviderSignUp>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setSignUps([]);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            rowHoverBg: '#e6f4ff',
          },
        },
      }}
    >
      <span style={{ marginRight: '20px' }}>Select Role:</span>
      <Radio.Group
        value={selectedRole}
        onChange={(e) => setSelectedRole(e.target.value)}
        style={{ marginBottom: '20px' }}
      >
        <div style={{ marginRight: '20px', display: 'inline-block' }}>
          <Badge count={unverifiedTherapistsCount}>
            <Radio.Button value="therapist">Therapists</Radio.Button>
          </Badge>
        </div>
        <Badge count={unverifiedPrescribersCount}>
          <Radio.Button value="prescriber">Prescribers</Radio.Button>
        </Badge>
      </Radio.Group>
      <Table
        rowKey={(record) => record.providerId}
        columns={columns}
        pagination={{
          ...tableParams.pagination,
          position: ['bottomCenter'],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultPageSize: 10,
        }}
        // rowHoverBg={'#e6f4ff'}
        dataSource={signUps}
        onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </ConfigProvider>
  );
};
export default NewSignUps;

import React, { createContext, useContext } from 'react';
import { LegalActionsSearchResult } from 'interfaces';

interface LegalActionsCheckerContextProps {
  legalActionsResults: LegalActionsSearchResult[];
  setLegalActionsResults: React.Dispatch<
    React.SetStateAction<LegalActionsSearchResult[]>
  >;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const LegalActionsCheckerContext =
  createContext<LegalActionsCheckerContextProps>(
    {} as LegalActionsCheckerContextProps
  );

export const useLegalActionsCheckerContext = () =>
  useContext(LegalActionsCheckerContext);

export const LegalActionsCheckerContextProvider: React.FC<{
  children: React.ReactNode;
  value: LegalActionsCheckerContextProps;
}> = ({ children, value }) => (
  <LegalActionsCheckerContext.Provider value={value}>
    {children}
  </LegalActionsCheckerContext.Provider>
);

import React, { useEffect, useState } from 'react';
import { Input, message, Select, Space } from 'antd';
import { LegalActionsSearchResult } from 'interfaces';
import { getLegalActionsSearchResults } from 'services/api';
import { useLegalActionsCheckerContext } from './LegalActionsCheckerContext';

const { Search } = Input;

const cache: { [key: string]: LegalActionsSearchResult[] } = {};

const SearchLegalActions: React.FC = () => {
  const { setLegalActionsResults, page, isLoading, setIsLoading } =
    useLegalActionsCheckerContext();

  const [query, setQuery] = useState('');
  const [location, setLocation] = useState('us');
  const [language, setLanguage] = useState('en');

  const handleSearch = async () => {
    try {
      setIsLoading(true);

      const cacheKey = `${query}_${location}_${language}_${page}`;
      if (cache[cacheKey]) {
        setLegalActionsResults(cache[cacheKey]);
        return;
      }

      const response = await getLegalActionsSearchResults(
        query,
        page,
        location,
        language
      );

      if (response.status === 200) {
        cache[cacheKey] = response.data.taggedLegalActions;
        setLegalActionsResults(response.data.taggedLegalActions);
      } else {
        message.error('Error fetching legal actions');
      }
    } catch (error) {
      message.error('Error fetching legal actions');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (query === '') return;
    handleSearch();
  }, [query, page]);

  return (
    <>
      <Space wrap>
        Location:
        <Select
          defaultValue="us"
          style={{ width: 130 }}
          onChange={setLocation}
          showSearch
          optionFilterProp="label"
          options={[
            { value: 'us', label: 'United States' },
            { value: 'ng', label: 'Nigeria' },
            { value: 'es', label: 'Spain' },
            { value: 'ca', label: 'Canada' },
            { value: 'mx', label: 'Mexico' },
            { value: 'au', label: 'Australia' },
            { value: 'za', label: 'South Africa' },
            { value: 'ch', label: 'Chile' },
            { value: 'co', label: 'Colombia' },
            { value: 'nz', label: 'New Zealand' },
          ]}
        />
        Languague:
        <Select
          defaultValue="en"
          onChange={setLanguage}
          style={{ width: 120 }}
          options={[
            { value: 'en', label: 'English' },
            { value: 'es', label: 'Spanish' },
            { value: 'pt', label: 'Portuguese' },
          ]}
        />
      </Space>
      <Search
        style={{ marginBottom: '10px', marginTop: '10px' }}
        loading={isLoading}
        onSearch={(value) => {
          setQuery(value);
        }}
        placeholder="Search"
        enterButton
      />
    </>
  );
};
export default SearchLegalActions;

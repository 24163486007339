import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import {
  LegalActionsSearchResults,
  NpiUserData,
  ProviderAirtableData,
  ProviderMongoDBData,
  ProviderSignUps,
  UnverifiedProvidersCount,
  VerificationData,
} from 'interfaces';

const hostname = window?.location?.hostname || '';

export function getBaseUrl(): string {
  if (process.env.NODE_ENV === 'development' || hostname.includes('localhost'))
    return 'http://localhost:3001/verification-hub-api';

  if (hostname.includes('stage'))
    return 'https://stage.wellnite.co/verification-hub-api';

  if (hostname.includes('wellnite.co'))
    return 'https://app.wellnite.co/verification-hub-api';

  return '';
}

const BASE_URL = getBaseUrl();

// axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'any value';
axios.defaults.withCredentials = true;

export const sendAccessTokenCode = async (code: string) => {
  try {
    const url = `${BASE_URL}/auth`;
    const response = await axios.post(url, { code });
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error('Error:', error);
      throw error;
    }
  }
};

export const getGoogleUserInfo = async () => {
  try {
    const url = `${BASE_URL}/auth`;
    const response = await axios.get(url);
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error('Error:', error);
      throw error;
    }
  }
};

export const logoutGoogleUser = async () => {
  try {
    const url = `${BASE_URL}/auth/logout`;
    const response = await axios.get(url);
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error('Error:', error);
      throw error;
    }
  }
};

export const fetchProviderDataFromAirTable = async (
  providerEmail: string
): Promise<{ status: HttpStatusCode; data: ProviderAirtableData }> => {
  try {
    const url = `${BASE_URL}/airtable/${providerEmail}`;
    const response: AxiosResponse<ProviderAirtableData> = await axios.get(url);
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error('Error fetching provider data from airtable:', error);
      throw error;
    }
  }
};

export const fetchNpiData = async (
  source: 'npi' | 'airtable',
  npiCode: string
): Promise<{ status: HttpStatusCode; data: NpiUserData }> => {
  try {
    const url = `${BASE_URL}/${source}`;
    const response: AxiosResponse<NpiUserData> = await axios.post(url, {
      npiCode,
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error(`Error fetching NPI data from ${source}:`, error);
      throw error;
    }
  }
};

export const fetchNewSignUps = async (
  query: string
): Promise<{
  status: HttpStatusCode;
  data: ProviderSignUps;
}> => {
  try {
    const response: AxiosResponse<ProviderSignUps> = await axios.get(
      `${BASE_URL}/providers?${query}`
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error('Error fetching new sign-ups:', error);
      throw error;
    }
  }
};

export const fetchUnverifiedProvidersCount = async (): Promise<{
  status: HttpStatusCode;
  data: UnverifiedProvidersCount;
}> => {
  try {
    const response: AxiosResponse<UnverifiedProvidersCount> = await axios.get(
      `${BASE_URL}/providers/count/unverified`
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error('Error fetching unverified providers count:', error);
      throw error;
    }
  }
};

export const getVerificationForm = async (
  providerId: string
): Promise<{
  status: HttpStatusCode;
  data: {
    verificationData: VerificationData;
    providerData: ProviderMongoDBData;
  };
}> => {
  try {
    const response: AxiosResponse<{
      verificationData: VerificationData;
      providerData: ProviderMongoDBData;
    }> = await axios.get(`${BASE_URL}/providers/${providerId}`);
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error('Error submitting verification form:', error);
      throw error;
    }
  }
};

export const submitVerificationForm = async (
  providerId: string,
  userEmail: string,
  formData: VerificationData
): Promise<{ status: HttpStatusCode; data: VerificationData }> => {
  try {
    const response: AxiosResponse<VerificationData> = await axios.post(
      `${BASE_URL}/providers/${providerId}`,
      { formData: formData, userEmail: userEmail }
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error('Error submitting verification form:', error);
      throw error;
    }
  }
};

export const setAirtableProviderVerificationStatus = async (
  providerEmail: string,
  providerVerificationStatus: 'approved' | 'rejected'
): Promise<{ status: HttpStatusCode; data: ProviderAirtableData }> => {
  try {
    const VERIFICATION_STATUS = {
      signaturePending: 'Signature Pending',
      notClean: 'Not clean',
    };
    const verificationData = {
      verificationStatus:
        providerVerificationStatus === 'approved'
          ? VERIFICATION_STATUS.signaturePending
          : VERIFICATION_STATUS.notClean,
    };
    const url = `${BASE_URL}/airtable/${providerEmail}`;
    const response: AxiosResponse<ProviderAirtableData> = await axios.post(
      url,
      verificationData
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error(
        'Error setting provider verification status on airtable:',
        error
      );
      throw error;
    }
  }
};

export const uploadFileURL = (providerEmail: string) => {
  return `${BASE_URL}/airtable/${providerEmail}/license`;
};

export const deleteCertificateFromAirtable = async (
  providerEmail: string,
  certificateId: string
): Promise<{ status: HttpStatusCode }> => {
  try {
    const url = `${BASE_URL}/airtable/${providerEmail}/license/${certificateId}`;
    const response: AxiosResponse = await axios.delete(url);
    return { status: response.status };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error(
        'Error deleting provider certificate from airtable:',
        error
      );
      throw error;
    }
  }
};

export const setAirtableProviderLicenseDate = async (
  providerEmail: string,
  licenseDateType: 'effective' | 'expiration',
  date: string | undefined
): Promise<{ status: HttpStatusCode; data: ProviderAirtableData }> => {
  try {
    const LICENSE_DATE_TYPE = {
      effective: 'providerStateLicenseEffectiveDate',
      expiration: 'providerStateLicenseExpirationDate',
    };
    const requestData = {
      [LICENSE_DATE_TYPE[licenseDateType]]: date || '',
    };
    const url = `${BASE_URL}/airtable/${providerEmail}`;
    const response: AxiosResponse<ProviderAirtableData> = await axios.post(
      url,
      requestData
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error(
        'Error setting provider verification status on airtable:',
        error
      );
      throw error;
    }
  }
};

export const getLegalActionsSearchResults = async (
  query: string,
  page: number,
  location: string,
  language: string
): Promise<{ status: HttpStatusCode; data: LegalActionsSearchResults }> => {
  try {
    const url = `${BASE_URL}/legal-actions-search`;
    const response: AxiosResponse<LegalActionsSearchResults> = await axios.get(
      url,
      { params: { query, page, location, language } }
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return handleAxiosError(error);
    } else {
      console.error('Error fetching legal actions search results:', error);
      throw error;
    }
  }
};

const handleAxiosError = (
  error: AxiosError
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { status: HttpStatusCode; data: null | any } => {
  return {
    status: error.response?.status || 500,
    data: error.response?.data || null,
  };
};

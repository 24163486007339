import React, { useState } from 'react';
import { LegalActionsSearchResult } from 'interfaces';
import { LegalActionsCheckerContextProvider } from './LegalActionsCheckerContext';
import ResultTable from './ResultTable';
import SearchLegalActions from './SearchLegalActions';

const LegalActionsChecker: React.FC = () => {
  const [legalActionsResults, setLegalActionsResults] = useState<
    LegalActionsSearchResult[]
  >([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LegalActionsCheckerContextProvider
      value={{
        legalActionsResults,
        setLegalActionsResults,
        page,
        setPage,
        isLoading,
        setIsLoading,
      }}
    >
      <SearchLegalActions />
      <ResultTable />
    </LegalActionsCheckerContextProvider>
  );
};
export default LegalActionsChecker;
